#author-picture {
  border-radius: 50%;
  filter: grayscale(0.5);
  margin: 0;
  max-width: 100px;
}

@media (min-width: $medium-breakpoint) {
  #author-picture {
    //float: right;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .site-name {
    clear: right;
  }
}

@media (max-width: $medium-breakpoint) {
  #author-picture {
    margin: 20px auto;
    margin-top: 0;
    //margin-bottom: 20px;
  }
  .site-name {
    //clear: right;
  }
}

.wallet-address {
  text-align: center;
}

.btn {
  height: 35px;
  margin-right: 30px;
  padding: 7px 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #FFFFFF;
  text-align: center;
  border-radius: 1px;
}

.btn-support {
  background: #FF9500;
  color: #fff;
}

.post-body footer {
  padding: 30px 0;
}

#sidebar-icon-links {
  margin-top: 0;
  i {
    font-size: 1.1rem;
  }
}

#sidebar nav a {
  &:hover {
    color: #869DBA;
  }
}

#sidebar nav > .icon {
  margin: 0 0.3em;
  &:hover {
    color: #869DBA;
  }
}

#sidebar {
  @media (min-width: 576px) {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    padding-bottom: 1rem;
    > :nth-last-child(2) {
      margin-bottom: auto;
    }
    #copyright {
      margin-top: auto;
      font-size: 0.9rem;
    }
  }
}

/* Theme */

.home-container {
  @media (max-width: 575px) {
    display: none;
  }
}

a.button {
  display: inline-block;
  font-weight: 600;
  border: 2px solid $link-color;
  border-radius: 5px;
  color: $link-color;
  padding: 0.75em 1em;
  position: relative;
  text-decoration: none;

  &:hover {
    background-color: $link-color;
    color: white;
    cursor: pointer;
  }

  &.center {
    margin: 0 auto;
  }
}

img.no-align {
  margin: 15px 0;
}

ul.archive {
  .listing-post {
    .post-date {
      float: right;
    }
  }
}