@import "hydeout";

body {
    background-color: #485564;
    background-image: linear-gradient(to bottom, #4f5d6e, #333c46);
}

#sidebar {
    .tagline {
        ul {
            padding: 0;
            list-style: none;
        }
    }
}

@media (max-width: 575px) {
    div.project-cards {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;

        a.project-card {
            text-decoration: none;
        }
    }
}

@media (min-width: 576px) {
    div.project-cards {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        a.project-card {
            flex: 0 1 48%;
            text-decoration: none;
        }
    }
}

@media (max-width: 783px) {
    #sidebar {
        .tagline {
            //display: none;
        }
    }
    .home {
        #main {
            display: none;
        }
    }
    #author-picture {
        display: none;
    }
    .tagline {
        display: none;
    }
    .home {
        #author-picture {
            display: block;
        }
        .tagline {
            display: block;
        }
        .home-link {
            display: none;
        }
    }
    .site-name {
        display: inline;
    }
    
    #copyright {
        padding-top: 30px;
    }
    #main {
        .page-title {
            margin-top: 30px;
        }
    }
}

div.project-cards {
    a.project-card {
        color: $body-color;
        padding: 15px;
        margin-top: 15px;
        margin-bottom: 15px;

        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;

        &:hover {
            box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
        }

        .tags {
            font-size: 0.8rem;
        }
    }
}

div.deprecated {
    border: 1px solid red;
    padding: 10px;
    margin-bottom: 15px;
    h3 {
        margin-top: 0.5rem;
    }
    p {
        margin-bottom: 0.5rem;
    }
}

p.deprecated {
    font-weight: bold;
    color: red;
}

div.gallery {
    div.picture {
        margin: 5px;
        border: 1px solid #ccc;
        img {
            width: 100%;
            height: auto;
            margin: 0;
            border-radius: 0;
        }
        div.title {
            padding: 15px;
            text-align: center;
        }
    }
}

.home {
    .back-arrow {
        display: none;
    }
}